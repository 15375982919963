@font-face {
  font-family: "EMprint-Regular";
  src: url("./assets/fonts/EMprint/EMprint-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "EMprint-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #041937;
  background-image: url("./assets/images/Mobil_Pattern_darkblue.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: hard-light;
  background-attachment: fixed;
}

.App {
  text-align: center;
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-small-logo {
  width: 20%;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 250px;
}

.App-link {
  width: 100%;
}

.App-link-logo {
  width: 100%;
  height: auto;
}

.App-link-android {
  width: 115%;
}
